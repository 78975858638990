// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-blog-templates-blog-post-js": () => import("./../../../src/blogTemplates/blogPost.js" /* webpackChunkName: "component---src-blog-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cultura-js": () => import("./../../../src/pages/cultura.js" /* webpackChunkName: "component---src-pages-cultura-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labs-js": () => import("./../../../src/pages/labs.js" /* webpackChunkName: "component---src-pages-labs-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-showcase-js": () => import("./../../../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */)
}

